import styled from 'styled-components';

export const KalenderEventContainer = styled.div`
display: flex;
justify-content: space-between;

@media screen and (max-width: 800px) {
  margin: auto;
  flex-direction: column;
  gap: 20px;
}
`;