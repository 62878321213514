import styled from 'styled-components';
import { globalScheme } from '../../global.styles';

export const KalenderContainer = styled.div`
  max-width: 45%;
  display: flex;
  flex-direction: column;

  .highlightTraining {
    background: ${globalScheme.darkgreen};
    color: white;
  }
  
  @media screen and (max-width: 800px) {
    max-width: 100vw;
  }
`;

export const KalenderTitle = styled.h2`
  margin: 10px 0;

  @media screen and (max-width: 800px) {
    font-size: 22px;
    display: flex;
  }
`;
