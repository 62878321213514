import React from 'react';

import { 
  FooterContainer,
  FooterOptionContainer,
  FooterOptionLinkContainer,
  CopyrightContainer,
  Copyright
 } from './footer.styles';

var date_year = new Date().getFullYear();
if (date_year === undefined) {
  date_year = '2024';
}

const Footer = () => {
  return (
    <FooterContainer>
      <FooterOptionContainer>
        <FooterOptionLinkContainer to='/start/impressum'>Impressum</FooterOptionLinkContainer>
        <FooterOptionLinkContainer to='/start/datenschutz'>Datenschutz</FooterOptionLinkContainer>
      </FooterOptionContainer>
      <CopyrightContainer>
        <Copyright>&#169;{date_year}</Copyright>
        <Copyright>{'Bogensport '.toUpperCase()}</Copyright>
        <Copyright>{'Dortmund'.toUpperCase()}</Copyright>
      </CopyrightContainer>
    </FooterContainer>
  )
};

export default Footer;