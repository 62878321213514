import { createGlobalStyle } from 'styled-components';

export const globalScheme = {
  darkgreen: "#1E5631",
  limegreen: "#A4DE02",
  darklimegreen: "#76BA1B",
  maxgreen: "#4C9A2A",
  lightgreen: "#ACDF87",
  mantisgreen: "#68BB59",
  background: "#E8E8E8"
}

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Verdana';
    padding: 2vh 3vh;
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;

    background: ${globalScheme.background};
    flex: 1; 
    
    @media screen and (max-width: 800px) {
      padding: 1vh 1vh;
    }
  }

  a {
    text-decoration: none;
    color: black;
  }

  * {
    box-sizing: border-box;
  }
`;