import styled from "styled-components";

export const MentalitätPageContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 50%;
  min-height: 82vh;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 30px;

  @media screen and (max-width: 800px) {
    min-height: 80vh;
    width: unset;
    box-shadow: unset;
    border-radius: unset;
    padding: 15px 0;
    max-width: unset;
  }
`;

export const ImageContainer = styled.img`
  display: flex;
  margin: 40px auto;
  max-width: 70%;
  object-fit: contain;
  
  @media screen and (max-width: 800px) {
    max-width: unset;
    display: unset;
  }

`;
