import styled  from 'styled-components';

const theme = {
  background: "white",
  primary: "#008000",
  secondary: "#1effc3",
  cardsize: "450px",
  cardheight: "200px"
};

export const CardContainer = styled.div`
  border-radius: 0.75rem;
  border-color: ${ theme.primary };
  max-width: ${ theme.cardsize };
  max-height: ${ theme.cardheight };
	overflow: hidden;
	border: 1px solid black;
	overflow: hidden;
  background: white;

  display: flex;
  gap: 5px;

  color: black;
  
  will-change: transform;
  transition: transform 0.25s cubic-bezier(0.4, 0.0, 0.2, 1);
  
  &:hover {
     transform: scale(1.1);
  }

  @media screen and (max-width: 800px) {
    max-width: 450px;
  }
`;

export const CardAvatarImage = styled.img`
  height: 50px;
`

export const CardAvatarContainer = styled.div`
  max-widht: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
`;

export const CardDetailsContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 4px;
  padding-right: 5px;
  align-items: stretch;

  @media screen and (max-width: 800px) {
    gap: 2px;
  }
`;

export const NameContainer = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  @media screen and (max-width: 800px) {
    font-size: 1.10rem;
  }
`

export const SeasonContainer = styled.div`
  font-weight: 600;
  color: ${theme.primary};
`;



export const CardAboutContainer = styled.div`
  margin-top: 5px;
  display: flex;
  gap: 10px;
  grid-auto-flow: column;
  justify-content: end;
  align-items: stretch;
  
`;

export const CardAboutItemContainer = styled.div` 
  display: flex;
  flex-flow: column wrap;
  gap: 10px;
`;

export const ValueContainer = styled.span`
  font-size: 1.0rem;

  @media screen and (max-width: 800px) {
    font-size: 0.75rem;
  }
`
  
export const LabelContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: end;
  font-size: 0.75rem;
  font-weight: 600;
  color: ${theme.primary};

  @media screen and (max-width: 800px) {
    font-size: 0.75rem;
  }
`