import React from 'react';

import KalenderCollection from '../../components/kalender-collection/kalender-collection.component';
import { KalenderPageContainer } from './kalender-page.styles';

const KalenderPage = () => {
  return (
    <KalenderPageContainer>
      <KalenderCollection />
    </KalenderPageContainer>
  );
}

export default KalenderPage;