import React from 'react';

import targetImg from '../../assets/calendar/target.png';

import 'react-calendar/dist/Calendar.css';
import { 
  EventContainer,
  EventContainerTitle,
  EventCardCollectionContainer} from './event-uebersicht.styles';
import { EventVisitenKarte } from '../visitenkarte/visitenkarte.component';

const EventUebersicht = ({ dates }) => {

  return (
    <EventContainer>
      <EventContainerTitle>Bevorstehende Events</EventContainerTitle>
      <EventCardCollectionContainer>
      {
        dates.map(({ id, title, formattedDate, time, description }) => (
          <EventVisitenKarte key={id} title={title} avatar={targetImg}
           date={formattedDate} time={time} description={description} />
        ))
      }
      </EventCardCollectionContainer>
    </EventContainer>
  );
}

export default EventUebersicht;