import styled from "styled-components";

export const ImpressumPageContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 45%;
  text-align: start;
  min-height: 82vh;
  font-size: large;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 30px;

  @media screen and (max-width: 800px) {
    min-height: 80vh;
    max-width: unset;
    box-shadow: unset;
    border-radius: unset;
    padding: 15px 0;
    font-size: medium;
  }
`;

export const ImpressumWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2vh;

  @media screen and (max-width: 800px) {
    display: block;
  }
`

export const ImpressumContactAndLogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: start;
  }
`

export const ImpressumLogoContainer = styled.div`
    display: flex;
    max-height: 100px;
    justify-content: center;
    object-fit: cover;

    @media screen and (max-width: 800px) {
      max-height: unset;
      width: 100%;
      height: 80px;
    }
`;

export const ImageContainer = styled.img`
  width: 80px;
  height: 100%;
  @media screen and (max-width: 800px) {
    padding-top: 10px;
  }
`