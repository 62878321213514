import styled from "styled-components";

export const ContactPageContainer = styled.div`
  display: flex;
  min-height: 82vh;
  margin: auto;
  padding: 5vh 5vh;

  @media screen and (max-width: 800px) {
    margin: auto;
    padding: 15px 0;
    min-height: 80vh;
  }
`;