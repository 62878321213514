import styled from "styled-components";
import { globalScheme } from "../../global.styles";

export const DatenschutzPageContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 60%;
  text-align: start;
  min-height: 82vh;
  font-size: large;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 800px) {
    min-height: 80vh;
    max-width: unset;
    box-shadow: unset;
    border-radius: unset;
    padding: 15px 0;
    font-size: medium;
  }
`;

export const CollapsibleContainer = styled.div`
  .Collapsible {
    background-color: white;
  }

  .Collapsible__contentInner {
    padding: 10px;
  }



  .Collapsible__trigger {
    font-size: larger;
    display: flex;
    justify-content: space-between;
    border: 1px solid white;
    align-items: center;
    padding: 5px 10px;
    &:hover {
      cursor: pointer;
    }
    background: ${globalScheme.darkgreen};
    color: white; 

    
    @media screen and (max-width: 800px) {
      font-size: large;
    }
  }

  .Collapsible__trigger:after {
    content: "\\2B9F";
    font-size: 28px;
    display: block;

    transition: transform 200ms; 
  }

  .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg); 
  }

  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; 
  }
  `;