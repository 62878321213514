import styled from "styled-components";
import { Link } from "react-router-dom";
import { globalScheme } from "../../global.styles";

export const FooterContainer = styled.div` 
  height: 50px;
  display: flex;
  font-weight: 600;
  padding-top: 1vh;
  border-top: 3px solid ${globalScheme.darkgreen};
  justify-content: space-between;

  @media screen and (max-width: 800px) {
      height: 30px;
      padding-top: 1vh;
      margin-bottom: 2vh;
      font-size: 12px;
  }
`;

export const FooterOptionContainer = styled.div` 
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 20px;

  @media screen and (max-width: 800px) {
    gap: 5px;
    flex-direction: column;
  }
`;

export const FooterOptionLinkContainer = styled(Link)`
  cursor: pointer;
  &:hover {
    color: ${globalScheme.darkgreen};
  }

  @media screen and (max-width: 800px) {
  }
`;

export const CopyrightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 8px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: unset;
  }
`;

export const Copyright = styled.span`
`;

