import styled from "styled-components";

export const KalenderPageContainer = styled.div`
  min-height: 82vh;
  margin: auto auto;
  max-width: 60%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 30px;

  @media screen and (max-width: 800px) {
    min-height: 80vh;
    max-width: unset;
    box-shadow: unset;
    border-radius: unset;
    padding: 15px 0;
  }
`;