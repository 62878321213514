import styled  from 'styled-components';

export const EventContainer = styled.div`
  max-width: 55%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    width: unset;
    max-width: unset;
  }
`;

export const EventContainerTitle = styled.h2`
  margin: 10px 0;

  @media screen and (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

export const EventCardCollectionContainer = styled.div`
  font-size: large;
  font-weight: bolder;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media screen and (max-width: 800px) {
    font-size: small;
    gap: 10px;
  }
`;