import React from 'react';

import bsImg from '../../assets/header/BSLogo.png';
import faqImg from '../../assets/header/faqs2.png';

import { 
  HeaderContainer,
  LogoContainer1,
  OptionsContainer,
  OptionLink,
  WelcomeContainer,
  H1Container,
  H1Container2,
  FaqImgContainer,
  BsImgContainer
} from './header.styles';

const Header = () => {
    return (
      <HeaderContainer>
        <LogoContainer1 to='/start'>
          <BsImgContainer src={bsImg} alt="bsImg" />
        </LogoContainer1>
        <WelcomeContainer>
          <H1Container>BOGENSPORT</H1Container>
          <H1Container2>DORTMUND</H1Container2>
        </WelcomeContainer>
        <OptionsContainer>
          <OptionLink to='/start/faq'>
            <FaqImgContainer src={faqImg} alt="faqImg" />
          </OptionLink>
        </OptionsContainer>
      </HeaderContainer>
    )
};

export default Header;