import styled from "styled-components";
import { Link } from "react-router-dom";
import { globalScheme } from "../../global.styles";

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 65px;
    max-height: 70px;
    padding-bottom: 1vh;
    border-bottom: 3px solid ${globalScheme.darkgreen};

    @media screen and (max-width: 800px) {
        max-height: 60px;
        padding-bottom: 5px;
        justify-content: space-around;
    }
`;

export const WelcomeContainer = styled.div`
  display: flex;
  font-weight: 600;
  font-size: xxx-large;
  text-align: center;
  max-height: 70px;
  overflow: hidden;

  @media screen and (max-width: 800px) {
    font-size: large;
    flex-direction: column;
    max-height: 50px;
  }
`

export const H1Container = styled.span`
    color: black;
`;

export const H1Container2 = styled.span`
    padding-left: 20px;
    color: ${globalScheme.darkgreen};

    @media screen and (max-width: 800px) {
        padding-left: unset;
    }
`;

export const LogoContainer1 = styled.div`
    display: flex;
    max-height: 100%;
    max-width: 20%;
    overflow: hidden;
    justify-content: flex-start;

    @media screen and (max-width: 800px) {
        max-height: 50px;
        max-width: 100px;
    }
`;

export const OptionsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    max-height: 100%;
    max-width: 20%;
`;

export const OptionLink = styled(Link)`
    display: flex;
    cursor: pointer;
    max-height: 70px;
    justify-content: flex-end;

    @media screen and (max-width: 800px) {
        max-height: 50px;
    }
`;

export const FaqImgContainer = styled.img`
    display: flex;
    max-height: 70px;
    justify-content: flex-end;

    @media screen and (max-width: 800px) {
        max-height: 50px;
    }
`;

export const BsImgContainer = styled.img`
    display: flex;
    max-height: 70px;
    justify-content: flex-start;

    @media screen and (max-width: 800px) {
        max-height: 50px;
        max-width: 100px;
    }
`;