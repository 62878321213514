import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { NavbarContainer, NavbarLink, NavbarLinkSlashCombination, NavbarSlash } from "./navbar.styles";

const NavRouterLink = ({ fullpath, page }) => {
    const title = page.charAt(0).toUpperCase() + page.slice(1);

    return <NavbarLinkSlashCombination>
            <NavbarSlash>/</NavbarSlash>
            <NavbarLink to={`/${fullpath.join('/')}`}>{title.toLowerCase()}</NavbarLink>
        </NavbarLinkSlashCombination>
  };


class NavRouter extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired
    };
    
    render() {
        const { location } = this.props;
        let locationArr = location.pathname.split("/");
        locationArr.shift();
        return  <NavbarContainer>
            {
                locationArr.map((loc, i) => <NavRouterLink fullpath={locationArr.slice(0, i+1)} page={loc} key={i} />)
            }
            </NavbarContainer>     
    }
};

const NavRouterBar = withRouter(NavRouter);  

export default NavRouterBar;