import { Link } from 'react-router-dom';
import styled  from 'styled-components';
import { globalScheme } from '../../global.styles';

export const NavbarContainer = styled.div`
    display: flex;
    max-height: 50px;
    justify-contetn: flex-start;
    border: ${globalScheme.darkgreen};
    border-style: none solid solid solid;
    box-sizing: border-box;

    @media screen and (max-width: 800px) {
    }
`;

export const NavbarLink = styled(Link)`
    &:hover {
        fill: ${globalScheme.darkgreen};
        transform: scale(1.1);
    }
   
    @media screen and (max-width: 800px) {
        
    }
`;

export const NavbarSlash = styled.div`
    padding-left: 1vh;
`;

export const NavbarLinkSlashCombination =  styled.div`
    display: flex;
    gap: 1vh;
`