import React from 'react';
import Collapsible from 'react-collapsible';
import { ArticleContactWrapper, ArticleGridWrapper, ArticleTextOnly, Headline } from '../../components/article/article.component';
import { CollapsibleContainer, DatenschutzPageContainer } from './datenschutz.styles';

const gridLayout = {
  gap: "8px",
  space1: "max-content",
  space2: "auto",
  indexSpace: 0
}

const DatenschutzPage = () => (
  <DatenschutzPageContainer>
    <Headline text="Datenschutz&shy;erklärung"/>
    <CollapsibleContainer>
      <Collapsible trigger={"1. Datenschutz auf einen Blick"}>
        <ArticleTextOnly 
          title="Allgemeine Hinweise"
          text="Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
          passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen
          Sie unserer unter diesem Text aufgeführten Datenschutzerklärung."
        />
        <ArticleTextOnly 
          title="Wer ist verantwortlich für die Datenerfassung auf dieser Website?"
          text="Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
          können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen."
        />
        <ArticleTextOnly 
          title="Wie erfassen wir Ihre Daten?"
          text="Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um
          Daten handeln, die Sie in ein Kontaktformular eingeben."
          text2="Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
          des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten."
        />
        <ArticleTextOnly 
          title="Wofür nutzen wir Ihre Daten?"
          text="Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
          Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden."
        />
        <ArticleTextOnly 
          title="Welche Rechte haben Sie bezüglich Ihrer Daten?"
          text="Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
          gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
          Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
          können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
          bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu."
          text2="Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden."
        />
      </Collapsible>
      <Collapsible trigger={"2. Hosting"}>
        <ArticleTextOnly 
          title="Externes Hosting"
          text="Diese Website wird extern gehostet. Die personenbezogenen Daten, die auf dieser Website erfasst werden,
          werden auf den Servern des Hosters / der Hoster gespeichert. Hierbei kann es sich v. a. um IP-Adressen,
          Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe
          und sonstige Daten, die über eine Website generiert werden, handeln."
          text2="Das externe Hosting erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und
          bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
          Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
          Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
          Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung
          von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im
          Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar."
          text3="Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner
          Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.
          Wir setzen folgende(n) Hoster ein:"
        />
        <ArticleContactWrapper
          textArr={[
            {id:1,text:"Hofmeir Media GmbH"},
            {id:2,text:""},
            {id:3,text:"Kranzhornstr. 3"},
            {id:4,text:""},
            {id:5,text:"81825 München"},
            {id:6,text:""}  
          ]}
          gridSpaceIndex={gridLayout.indexSpace} gridSpace1={gridLayout.space1} gridSpace2={gridLayout.space2} gridGap={gridLayout.gap}
        />
      </Collapsible>
      <Collapsible trigger={"3. Allgemeine Hinweise und Pflichtinformationen"}>
        <ArticleTextOnly 
          title="Datenschutz"
          text="Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
          personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie
          dieser Datenschutzerklärung."
          text2="Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
          Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
          Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
          und zu welchem Zweck das geschieht."
          text3="Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
          Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich."
        />
        <ArticleTextOnly 
          title="Hinweis zur verantwortlichen Stelle"
          text="Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:"
        />
        <ArticleContactWrapper
          textArr={[
            {id:1,text:"TuS Scharnhorst 1895/1926 e.V."},
            {id:2,text:""},
            {id:3,text:"Gleiwitzstraße 281"},
            {id:4,text:""},
            {id:5,text:"44328 Dortmund"},
            {id:6,text:""}  
          ]}
          gridSpaceIndex={gridLayout.indexSpace} gridSpace1={gridLayout.space1} gridSpace2={gridLayout.space2} gridGap={gridLayout.gap}
        />
        <ArticleContactWrapper
        textArr={[
          {id:1,text:"Telefon:"},
          {id:2,text:"0231/5899143"},
          {id:3,text:"Fax:"},
          {id:4,text:"0231/22278480"},
          {id:5,text:"E-Mail:"},
          {id:6,text:"tusscharnhorst@dokom.net"},
        ]}
        gridSpaceIndex={gridLayout.indexSpace} gridSpace1={gridLayout.space1} gridSpace2={gridLayout.space2} gridGap={gridLayout.gap}
      />
        <ArticleTextOnly 
          text="Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
          die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
          entscheidet."
        />
        <ArticleTextOnly 
          title="Speicherdauer"
          text="Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben
          Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
          berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen,
          werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
          personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
          letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe."
        />
        <ArticleTextOnly 
          title="Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website"
          text="Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf
          Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien
          nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung
          personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art.
          49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in
          Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich
          auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
          Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre
          Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese
          zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO.
          Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
          DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden
          Absätzen dieser Datenschutzerklärung informiert."
        />
        <ArticleTextOnly 
          title="Widerruf Ihrer Einwilligung zur Datenverarbeitung"
          text="Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
          bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitung bleibt vom Widerruf unberührt."
        />
        <ArticleTextOnly 
          title="Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)"
          text="Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO
          erfolgt, haben sie jederzeit das Recht, aus Gründen, die sich aus ihrer besonderen
          Situation ergeben, gegen die Verarbeitung ihrer personenbezogenen Daten
          Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes
          Profiling. Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht,
          entnehmen sie dieser Datenschutzerklärung. wenn sie Widerspruch einlegen,
          werden wir ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es
          sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung
          nachweisen, die ihre Interessen, Rechte und Freiheiten überwiegen oder die
          Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO)."
          text2="Werden ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben,
          so haben sie das Recht, jederzeit Widerspruch gegen die Verarbeitung sie
          betreffender personenbezogener Daten zum Zwecke derartiger Werbung
          einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in
          Verbindung steht. wenn sie widersprechen, werden ihre personenbezogenen Daten
          anschliessend nicht mehr zum zwecke der Direktwerbung verwendet (Widerspruch
          nach Art. 21 Abs. 2 DSGVO)."
        />
        <ArticleTextOnly 
          title="Beschwerderecht bei der zuständigen Aufsichtsbehörde"
          text="Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
          Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
          oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
          verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe."
        />
        <ArticleTextOnly 
          title="Recht auf Datenübertragbarkeit"
          text="Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
          automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
          verlangen, erfolgt dies nur, soweit es technisch machbar ist."
        />
        <ArticleTextOnly 
          title="Auskunft, Löschung und Berichtigung"
          text="Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
          Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
          Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie
          zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden."
        />
        <ArticleTextOnly 
          title="Recht auf Einschränkung der Verarbeitung"
          text="Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
          folgenden Fällen:"
        />
        <ArticleGridWrapper
          textArr={[
            {id:1,text:"●"},
            {id:2,text:"Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen."},
            {id:3,text:"●"},
            {id:4,text:"Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen."},
            {id:5,text:"●"},
            {id:6,text:"Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen."},  
            {id:7,text:"●"},
            {id:8,text:"Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen."},
          ]}
          gridSpaceIndex={gridLayout.indexSpace} gridSpace1={gridLayout.space1} gridSpace2={gridLayout.space2} gridGap={gridLayout.gap}
        />
        <ArticleTextOnly 
          text="Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
          ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
          juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder
          eines Mitgliedstaats verarbeitet werden."
        />
        <ArticleTextOnly 
          title="SSL- bzw. TLS-Verschlüsselung"
          text="Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
          Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLSVerschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von
          „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile."
          text2="Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden."
        />
      </Collapsible>
    </CollapsibleContainer>
  </DatenschutzPageContainer>
);

export default DatenschutzPage;